import Vue from 'vue'
import VueRouter from 'vue-router'

//路由组加载




//组件加载
const NotFoundView = () => import('@/views/index/NotFoundView')
const IndexView = () => import('@/views/index/IndexView')
const HomeView = () => import('@/views/index/HomeView')
const LoginView = () => import('@/views/login/LoginView')

//路由组加载
import RouterTeamRouter from './RouterTeamRouter'
import RouterRouter from './RouterRouter'
import CardRoleRouter from './card/CardRoleRouter'
import CardFunRouter from './card/CardFunRouter'
import UserRouter from './UserRouter'
import AuthTeamRouter from './AuthTeamRouter'
import GameRecordMyRouter from './query/GameRecordMyRouter'
import GameRecordAllRouter from './query/GameRecordAllRouter'
import QueryRoleRouter from './query/QueryRoleRouter'
import ShopArticleRouter from './shop/ShopArticleRouter'
import ShopSkinRouter from './shop/ShopSkinRouter'
import ShopBoxRouter from './shop/ShopBoxRouter'
import ShopNickRouter from './shop/ShopNickRouter'
import ShopSkinDtRouter from './shop/ShopSkinDtRouter'
import ShopBackRouter from './shop/ShopBackRouter'
import CdKeyRouter from './query/CdKeyRouter'
import TaskRouter from './query/TaskRouter'
import ScoreRouter from './query/ScoreRouter'
import ForumRouter from './ForumRouter'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: IndexView,
  children: [{
    path: '', //path为空，会作为默认子路由渲染
    name: 'HomeRouter',
    // redirect: '/MyInformation'
    component: HomeView
  }].concat(
    RouterTeamRouter,
    RouterRouter,
    CardRoleRouter,
    CardFunRouter,
    UserRouter,
    AuthTeamRouter,
    GameRecordMyRouter,
    GameRecordAllRouter,
    QueryRoleRouter,
    ShopArticleRouter,
    ShopSkinRouter,
    ShopBoxRouter,
    ShopNickRouter,
    ShopSkinDtRouter,
    ShopBackRouter,
    CdKeyRouter,
    TaskRouter,
    ScoreRouter,
    ForumRouter
  )
},{
  path:'/login',
  name:'LoginRouter',
  component:LoginView
},{
  path:'/404',
  component:NotFoundView 
},{
  path:'*',
  redirect: '/404'
}]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//解决重复访问同一个路由报错的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  //公共页面
  if (to.path === '/login') {
    if (sessionStorage.getItem('token')) {
      //跳转到首页
      location.replace('/')
      return
    }
  } else {
    if (!sessionStorage.getItem('token')) {
      //跳转到登录页面
      location.replace('/login')
      return
    }
  }
  next()
})

export default router